import React from 'react';
import { createRoot } from 'react-dom/client';
import AppWithMSAL from './AppWithMSAL';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { ToastContainer } from 'react-toastify';
import './styles/App.scss';

const root = createRoot(document.getElementById('root'));
root.render(
	<React.StrictMode>
		<AppWithMSAL />
		<ToastContainer className="pb-3 pb-md-0" position="bottom-center" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss={false} draggable pauseOnHover theme="colored" />
	</React.StrictMode>
);

const onUpdate = registration => {
	const waitingServiceWorker = registration.waiting;

	if (waitingServiceWorker) {
		waitingServiceWorker.addEventListener("statechange", event => {
			if (event.target.state === "activated") {
				// Currently reloading the app as soon as a new version is available. Ensure any updates won't cause issues.
				// if (window.confirm("There is a new version of the app ready. Please reload to update.")) {
				window.location.replace(window.location.origin + "/")
				// }
			}
		})
		waitingServiceWorker.postMessage({ type: "SKIP_WAITING" })
	}
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({ onUpdate: onUpdate });

const registerServiceWorkerOnAppFocus = () => {
	if (!document.hidden) {
		serviceWorkerRegistration.register({ immediate: true, onUpdate: onUpdate });
	}
}

window.document.addEventListener("visibilitychange", registerServiceWorkerOnAppFocus);