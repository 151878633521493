import { coc00nApiRedirectUrl, coc00nApiUrl, coc00nApiScope } from "./config";
import { InteractionRequiredAuthError, InteractionStatus } from "@azure/msal-browser";

export const callApi = async ({ instance, inProgress, accounts }, method, endpoint, body) => {
	if (inProgress === InteractionStatus.None) {
		// Get access token for AAD
		let request = {
			scopes: [coc00nApiScope],
			account: accounts[0]
		};
		let aadToken = null;
		try {
			aadToken = await instance.acquireTokenSilent(request);
		} catch (error) {
			if (error instanceof InteractionRequiredAuthError) {
				// fallback to interaction when silent call fails
				return instance.acquireTokenRedirect(request)
			} else {
				throw error;
			}
		}

		// Now exchange it for a token for the API
		let authOptions = {
			method: "POST",
			body: JSON.stringify({
				access_token: aadToken.accessToken,
				id_token: aadToken.idToken
			})
		};

		let apiTokenRes = await fetch(coc00nApiRedirectUrl, authOptions);
		let apiToken = await apiTokenRes.json();

		// Now we can call the API with this token
		let headers = new Headers();
		headers.append("X-ZUMO-AUTH", apiToken.authenticationToken);
		let options = {
			method: method,
			headers: headers
		};
		if (body) {
			options.body = JSON.stringify(body);
		}

		let apiRes = await fetch(coc00nApiUrl + endpoint, options);
		if (!apiRes.ok) {
			// console.log(new Error(await apiRes.text()));
			return []
		}
		return await apiRes.json();
	} else {
		return new Promise((resolve, reject) => {
			reject("msal is busy");
		})
	}
}