import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { NavLink } from "react-router-dom";
import { ReactComponent as Logo } from '../images/coc00n_logo_icon.svg';
import { ReactComponent as AccountIcon } from '../images/coc00n_account_icon.svg';
import { ReactComponent as MenuIcon } from '../images/menu_icon.svg';
import { ReactComponent as CloseMenuIcon } from '../images/close_menu_icon.svg';
import { ReactComponent as LogoWhite } from '../images/coc00n_logo_icon_white.svg';
import { useState } from 'react';

export default function Navigation({ notifications }) {
	const [show, setShow] = useState(false);

	return (
		<Navbar expand={false} className="py-3 bg-off-white" fixed="top" collapseOnSelect>
			<Container>
				<Navbar.Brand as={NavLink} to="/" className="flex-grow-1" title="Dashboard">
					<Logo style={{ height: (window.innerWidth < 576 ? "35" : "45") + "px" }} />
				</Navbar.Brand>
				<NavLink to="/account" title="My Account" className="me-4 me-md-5">
					<AccountIcon className="pointer" style={{ height: (window.innerWidth < 576 ? "30" : "35") + "px", width: (window.innerWidth < 576 ? "30" : "35") + "px" }} />
				</NavLink>
				<MenuIcon className="pointer" onClick={() => setShow(true)} style={{ height: (window.innerWidth < 576 ? "30" : "35") + "px", width: (window.innerWidth < 576 ? "30" : "35") + "px" }} />
				<Offcanvas placement="end" backdrop={false} show={show} onHide={() => setShow(false)} className="text-off-white">
					<Offcanvas.Header className="d-flex justify-content-end">
						<CloseMenuIcon className="pointer" onClick={() => setShow(false)} style={{ height: (window.innerWidth < 576 ? "20" : "25") + "px", width: (window.innerWidth < 576 ? "20" : "25") + "px", stroke: "#fff" }} />
					</Offcanvas.Header>
					<Offcanvas.Body className="d-flex flex-column p-5 align-items-start">
						<Nav className="fs-4 fw-bold d-flex flex-column">
							<Nav.Link as={NavLink} eventKey="1" active={false} to="/" onClick={() => setShow(false)}>Dashboard</Nav.Link>
							<Nav.Link as={NavLink} eventKey="2" active={false} to="/notifications" onClick={() => setShow(false)}>Notifications{notifications.filter(n => !n.read).length > 0 ? " | " + notifications.filter(n => !n.read).length + " | " : ""}</Nav.Link>
							<Nav.Link as={NavLink} eventKey="3" active={false} to="/concierge" onClick={() => setShow(false)}>Concierge</Nav.Link>
							<Nav.Link as={NavLink} eventKey="3" active={false} to="/account" onClick={() => setShow(false)}>My Account</Nav.Link>
						</Nav>
						-
						<Nav className="fs-6 fw-light d-flex flex-column flex-grow-1">
							<Nav.Link as={NavLink} eventKey="1" active={false} to="/settings" onClick={() => setShow(false)}>Settings</Nav.Link>
							<Nav.Link as={NavLink} eventKey="2" active={false} to="/faqs" onClick={() => setShow(false)}>FAQs</Nav.Link>
							<Nav.Link as={NavLink} eventKey="3" active={false} to="/about" onClick={() => setShow(false)}>About</Nav.Link>
							<Nav.Link as={NavLink} eventKey="4" active={false} to="/privacy" onClick={() => setShow(false)}>Privacy Policy</Nav.Link>
							{/* <Nav.Link as={NavLink} eventKey="5" active={false} to="/terms" onClick={() => setShow(false)}>Terms & Conditions</Nav.Link> */}
						</Nav>
						<LogoWhite style={{ height: "45px" }} />
					</Offcanvas.Body>
				</Offcanvas>
			</Container>
		</Navbar>
	);
}
