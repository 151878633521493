import { Suspense, lazy, useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { callApi } from './utils/api';
import Navigation from './components/Navigation';

const Dashboard = lazy(() => import('./pages/Dashboard'));
const WebProtection = lazy(() => import('./pages/WebProtection'));
const WebDomainsChecked = lazy(() => import('./pages/BlockedDomains'));
const EmailProtection = lazy(() => import('./pages/EmailProtection'));
const Quarantine = lazy(() => import('./pages/Quarantine'));
const Devices = lazy(() => import('./pages/Devices'));
const Concierge = lazy(() => import('./pages/Concierge'));
const Account = lazy(() => import('./pages/Account'));
const Breaches = lazy(() => import('./pages/Breaches'));
const Notifications = lazy(() => import('./pages/Notifications'));
const Settings = lazy(() => import('./pages/Settings'));
const Faqs = lazy(() => import('./pages/Faqs'));
const About = lazy(() => import('./pages/About'));
const Privacy = lazy(() => import('./pages/Privacy'));
const Terms = lazy(() => import('./pages/TermsAndConditions'));

export default function App() {
	const msal = useMsal();
	const [metrics, setMetrics] = useState(JSON.parse(localStorage.getItem("metrics")) || []);
	const [metricsByMonth, setMetricsByMonth] = useState(JSON.parse(localStorage.getItem("metricsByMonth")) || []);
	const [breaches, setBreaches] = useState(JSON.parse(localStorage.getItem("breaches")) || []);
	const [notifications, setNotifications] = useState(JSON.parse(localStorage.getItem("notifications")) || []);

	useEffect(() => {
		function getMetrics() {
			if (!document.hidden) {
				let storedMetrics = JSON.parse(localStorage.getItem("metrics")) || []; // Set to empty array to refresh all metrics
				if (storedMetrics.length < 30) { // remove latest 30 elements in array to always get the most up to date data
					storedMetrics = [];
				} else {
					storedMetrics = storedMetrics.slice(0, -30);
				}
				let queryString = "";
				// if it exists, get the latest metric and use datetime as fromDate
				if (storedMetrics?.length > 0) {
					let lastExpectedMetrics = new Date();
					lastExpectedMetrics.setUTCDate(lastExpectedMetrics.getUTCDate() - 31);
					lastExpectedMetrics.setUTCHours(23);
					lastExpectedMetrics.setUTCMinutes(59);
					lastExpectedMetrics.setUTCSeconds(59);
					lastExpectedMetrics.setUTCMilliseconds(999);
					if (storedMetrics[storedMetrics.length - 1].dateTime !== lastExpectedMetrics.toJSON()) {
						queryString = "?fromDate=" + storedMetrics[storedMetrics.length - 1].dateTime;
					} else {
						queryString = null;
					}
				}

				if (queryString !== null) {
					callApi(msal, "GET", "metrics" + queryString).then(returnedMetrics => {
						let allMetrics = storedMetrics.concat(returnedMetrics);
						// if over 2mb then we need to remove the first x elements to bring it down to size
						let sizeInBytes = JSON.stringify(allMetrics).length * 2;
						while (sizeInBytes > 2000000) {
							allMetrics.shift();
							sizeInBytes = JSON.stringify(allMetrics).length * 2;
						}
						localStorage.setItem("metrics", JSON.stringify(allMetrics));
						setMetrics(allMetrics);

						let allMetricsByMonth = mergeMetricsIntoMonthlyMetrics(allMetrics);
						localStorage.setItem("metricsByMonth", JSON.stringify(allMetricsByMonth));
						setMetricsByMonth(allMetricsByMonth);
					})
				}
			}
		}

		getMetrics();
		const getMetricsController = new AbortController();
		window.document.addEventListener("visibilitychange", getMetrics, { signal: getMetricsController.signal });
		return () => {
			getMetricsController.abort();
		}
	}, [msal])

	useEffect(() => {
		function getBreaches() {
			if (!document.hidden) {
				callApi(msal, "GET", "breaches").then(res => {
					localStorage.setItem("breaches", JSON.stringify(res));
					setBreaches(res);
				})
			}
		}

		getBreaches();
		const getBreachesController = new AbortController();
		window.document.addEventListener("visibilitychange", getBreaches, { signal: getBreachesController.signal });
		return () => {
			getBreachesController.abort();
		}
	}, [msal])

	useEffect(() => {
		function getNotifications() {
			if (!document.hidden) {
				// read localstorage
				let storedNotifications = JSON.parse(localStorage.getItem("notifications")) || [];
				let queryString = "";
				// if it exists, get the latest notification and use datetime as fromDate
				if (storedNotifications?.length > 0) {
					queryString = "?fromDate=" + (new Date(storedNotifications[storedNotifications.length - 1].data.dateTime)).toJSON();
				}

				callApi(msal, "GET", "notifications" + queryString).then(res => {
					let newNotifications = res.map(n => ({ read: window.location.pathname === "/notifications", ...n }));
					let allNotifications = storedNotifications ? storedNotifications.concat(newNotifications) : newNotifications;
					// if over .5mb then we need to remove the first x elements to bring it down to size
					let sizeInBytes = JSON.stringify(allNotifications).length * 2;
					while (sizeInBytes > 500000) {
						allNotifications.shift();
						sizeInBytes = JSON.stringify(allNotifications).length * 2;
					}
					localStorage.setItem("notifications", JSON.stringify(allNotifications));
					setNotifications(allNotifications);
				})
			}
		}

		getNotifications();
		const getNotificationsController = new AbortController();
		window.document.addEventListener("visibilitychange", getNotifications, { signal: getNotificationsController.signal });
		return () => {
			getNotificationsController.abort();
		}
	}, [msal])

	return (
		<BrowserRouter>
			<Navigation notifications={notifications} />
			<Suspense fallback={<></>}>
				<Routes>
					<Route path="/*" element={<Dashboard metrics={metrics} notifications={notifications} />} />
					<Route path="/webprotection" element={<WebProtection metrics={metrics} metricsByMonth={metricsByMonth} />} />
					<Route path="/checkeddomains" element={<WebDomainsChecked metrics={metrics} metricsByMonth={metricsByMonth} />} />
					<Route path="/emailprotection" element={<EmailProtection metrics={metrics} metricsByMonth={metricsByMonth} />} />
					<Route path="/quarantine" element={<Quarantine metricsByMonth={metricsByMonth} />} />
					<Route path="/devices" element={<Devices metricsByMonth={metricsByMonth} />} />
					<Route path="/concierge" element={<Concierge />} />
					<Route path="/account" element={<Account breaches={breaches} />} />
					<Route path="/breaches/:email" element={<Breaches breaches={breaches} />} />
					<Route path="/notifications" element={<Notifications notifications={notifications} setNotifications={setNotifications} />} />
					<Route path="/settings" element={<Settings />} />
					<Route path="/faqs" element={<Faqs />} />
					<Route path="/about" element={<About />} />
					<Route path="/privacy" element={<Privacy />} />
					<Route path="/terms" element={<Terms />} />
				</Routes>
			</Suspense>
		</BrowserRouter>
	);
}

function mergeMetricsIntoMonthlyMetrics(metrics) {
	let metricsByMonth = [];
	let givenMetrics = JSON.parse(JSON.stringify(metrics)); // otherwise we increment the first daily metric on file.
	givenMetrics.forEach(metric => {
		let latestDate = metricsByMonth.length > 0 ? new Date(metricsByMonth[metricsByMonth.length - 1].dateTime) : new Date(0);
		let metricDate = new Date(metric.dateTime);

		if (latestDate.getUTCFullYear() === metricDate.getUTCFullYear() && latestDate.getUTCMonth() === metricDate.getUTCMonth()) {
			let currentMonthMetrics = metricsByMonth.pop();
			// Set the date to the latest metric
			currentMonthMetrics.dateTime = metric.dateTime;

			// devices should be overwritten to show the state at the end of the month
			if (metric.hasOwnProperty("devices")) {
				currentMonthMetrics.devices = metric.devices;
			}

			// All keys in dns are numeric so can be added
			if (metric.hasOwnProperty("dns")) {
				Object.keys(metric.dns).forEach(key => {
					if (currentMonthMetrics.dns.hasOwnProperty(key)) {
						currentMonthMetrics.dns[key] += metric.dns[key];
					} else {
						currentMonthMetrics.dns[key] = metric.dns[key];
					}
				});
			}

			// All keys in email are numeric so can be added
			if (metric.hasOwnProperty("email")) {
				Object.keys(metric.email).forEach(key => {
					if (currentMonthMetrics.email.hasOwnProperty(key)) {
						currentMonthMetrics.email[key] += metric.email[key];
					} else {
						currentMonthMetrics.email[key] = metric.email[key];
					}
				});
			}

			// quarantined should include all emails quarantined that month
			if (metric.hasOwnProperty("quarantined")) {
				currentMonthMetrics.quarantined = currentMonthMetrics.quarantined.concat(metric.quarantined);
			}

			metricsByMonth.push(currentMonthMetrics);
		} else {
			// make sure the initial monthly metric has all the right keys
			let metricWithAllKeys = {
				devices: [],
				dns: {},
				email: {},
				quarantined: [],
				...metric
			}
			metricsByMonth.push(metricWithAllKeys);
		}
	});
	return metricsByMonth;
}