import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import SignInSignOutButton from '../components/SignInSignOutButton';
import { ReactComponent as Logo } from '../images/coc00n_logo_strap.svg';

export default function Unauthenticated() {

	return (
		<Container fluid>
			<Row>
				<Col className="vh-100 d-flex flex-column justify-content-center align-items-center p-5" id="unauthenticatedBackground">
					<Logo alt="coc00n logo" className="pb-5 w-50" />
					<div>
						<SignInSignOutButton />
					</div>
				</Col>
			</Row>
		</Container>
	)
}