import { useEffect, useState } from 'react';
import App from './App';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { ReactComponent as Logo } from './images/coc00n_logo_strap.svg';

export default function AppWithSplash() {
    const [splashComplete, setSplashComplete] = useState(false);

    useEffect(() => {
        setTimeout(() => setSplashComplete(true), 2000);
    }, []);

    return (
        <>
            {!splashComplete &&
                <Container fluid id="splashScreenContainer">
                    <Row>
                        <Col className="vh-100 d-flex flex-column justify-content-center align-items-center p-5 bg-off-white" id="splashScreen">
                            <Logo alt="coc00n logo" className="pb-5 w-50" />
                        </Col>
                    </Row>
                </Container>
            }
            <App />
        </>
    );
}