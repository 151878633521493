import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider, AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import Unauthenticated from './pages/Unauthenticated';
import { clientId } from "./utils/config";
import AppWithSplash from "./AppWithSplash";

const msalConfig = {
	auth: {
		clientId: clientId,
		authority: "https://login.microsoftonline.com/organizations",
		redirectUri: "/",
		postLogoutRedirectUri: "/",
		cloudDiscoveryMetadata: '{"tenant_discovery_endpoint":"https://login.microsoftonline.com/common/v2.0/.well-known/openid-configuration","api-version":"1.1","metadata":[{"preferred_network":"login.microsoftonline.com","preferred_cache":"login.windows.net","aliases":["login.microsoftonline.com","login.windows.net","login.microsoft.com","sts.windows.net"]},{"preferred_network":"login.partner.microsoftonline.cn","preferred_cache":"login.partner.microsoftonline.cn","aliases":["login.partner.microsoftonline.cn","login.chinacloudapi.cn"]},{"preferred_network":"login.microsoftonline.de","preferred_cache":"login.microsoftonline.de","aliases":["login.microsoftonline.de"]},{"preferred_network":"login.microsoftonline.us","preferred_cache":"login.microsoftonline.us","aliases":["login.microsoftonline.us","login.usgovcloudapi.net"]},{"preferred_network":"login-us.microsoftonline.com","preferred_cache":"login-us.microsoftonline.com","aliases":["login-us.microsoftonline.com"]}]}'
	},
	cache: {
		cacheLocation: "localStorage",
		storeAuthStateInCookie: false
	}
};

const msalInstance = await PublicClientApplication.createPublicClientApplication(msalConfig);

// Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
	// Account selection logic is app dependent. Adjust as needed for different use cases.
	const allAccounts = msalInstance.getAllAccounts();
	msalInstance.setActiveAccount(allAccounts[0]);
}

export default function AppWithMSAL() {
	return (
		<MsalProvider instance={msalInstance}>
			<AuthenticatedTemplate>
				<AppWithSplash />
			</AuthenticatedTemplate>
			<UnauthenticatedTemplate>
				<Unauthenticated />
			</UnauthenticatedTemplate>
		</MsalProvider>
	);
}